function init() {

  const menuBtn = document.querySelector('.l-header__menuBtn');
  const targetElement = document.querySelector('.l-headerSpNav');
  const navLinks = document.querySelectorAll('.l-headerSpNav li a');

  toggleMenuActive(menuBtn, targetElement);
  closeMenuOnLinkClick(navLinks, menuBtn, targetElement);
}

function toggleMenuActive(menuBtn, targetElement) {
  if (menuBtn && targetElement) {
    menuBtn.addEventListener('click', () => {
      menuBtn.classList.toggle('is-active');
      targetElement.classList.toggle('is-active');
    });
  }
}
function closeMenuOnLinkClick(navLinks, menuBtn, targetElement) {
  navLinks.forEach(link => {
    link.addEventListener('click', () => {
      menuBtn.classList.remove('is-active');
      targetElement.classList.remove('is-active');
    });
  });
}

export default {
  init,
};
