import Top from './projects/top';
import Gnav from './modules/gnav';
import InView from './modules/inview';

function init() {
  Top.init();
  Gnav.init();
  InView.init();
}

export default {
  init,
};
